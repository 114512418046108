/* eslint react/prop-types: 0 */
import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";
import {
  Dropdown,
  Segment,
  Container,
  Button,
  Confirm,
  Table
} from "semantic-ui-react";

export default props => {
  const { match } = props;
  const { customerId } = match.params;

  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    API.get(
      "audit-apis",
      `/admin/customers/${customerId}/user-permissions`,
      {}
    ).then(result => {
      setUsers(result.data);
    });
  }, [customerId]);

  useEffect(() => {
    API.get("audit-apis", `/admin/customers/${customerId}/settings`, {}).then(
      result => {
        const {
          data: { departments: departmentSettings }
        } = result;
        const departmentOptions = departmentSettings.map(dep => {
          const { name } = dep;
          return {
            key: name,
            text: name,
            value: name
          };
        });
        setDepartments(departmentOptions);
      }
    );
  }, [customerId]);

  const saveUser = useCallback(
    changedUser => {
      const { principalId } = changedUser;
      const userPermissions = _.pick(changedUser, [
        "userRoles",
        "userDepartments"
      ]);

      const allUsers = users.map(user => {
        if (user.principalId === principalId) {
          return {
            ...user,
            updated: false,
            loading: true
          };
        }
        return user;
      });
      setUsers(allUsers);
      console.log("putting permissions", userPermissions);
      API.put(
        "audit-apis",
        `/admin/customers/${customerId}/user-permissions/${principalId}`,
        {
          body: userPermissions
        }
      ).then(() => {
        setUsers(currUsers => {
          const allCurrUsers = currUsers.map(user => {
            if (user.principalId === principalId) {
              return {
                ...user,
                loading: false
              };
            }
            return user;
          });
          return allCurrUsers;
        });
      });
    },
    [users]
  );

  const updateUser = useCallback(
    updatedUser => {
      const { principalId } = updatedUser;
      const allUsers = users.map(user => {
        if (user.principalId === principalId) {
          return updatedUser;
        }
        return user;
      });
      console.log("updating", principalId, updatedUser, allUsers);
      setUsers(allUsers);
    },
    [users]
  );

  const roles = [
    { key: "auditor", text: "Auditor", value: "auditor" },
    {
      key: "addEditAudits",
      text: "Add/Edit Audits",
      value: "addEditAudits"
    },
    {
      key: "publishRecommendations",
      text: "Publish Recommendations",
      value: "publishRecommendations"
    },
    {
      key: "customerSettings",
      text: "Administer Settings",
      value: "customerSettings"
    },
    { key: "adminUsers", text: "Admin Users", value: "adminUsers" }
  ];

  const userUI = users.map(user => {
    const {
      principalId,
      userFullName,
      userEmail,
      updated,
      loading,
      userRoles = [],
      userDepartments = []
    } = user;
    return (
      <Table.Row key={principalId}>
        <Table.Cell>{userFullName}</Table.Cell>
        <Table.Cell>{userEmail}</Table.Cell>
        <Table.Cell>
          <Dropdown
            fluid
            multiple
            options={roles}
            value={userRoles}
            onChange={(e, v) =>
              updateUser({
                ...user,
                updated: true,
                userRoles: v.value
              })
            }
            placeholder="Roles"
          />
        </Table.Cell>
        <Table.Cell>
          <Dropdown
            fluid
            multiple
            options={departments}
            value={userDepartments}
            onChange={(e, v) =>
              updateUser({
                ...user,
                updated: true,
                userDepartments: v.value
              })
            }
            placeholder="Manged Departments"
          />
        </Table.Cell>
        <Table.Cell collapsing>
          <Button
            primary
            disabled={!updated}
            loading={loading}
            icon
            size="small"
            onClick={() => saveUser(user)}
          >
            Update
          </Button>
          <Confirm
            header="Delete Customer"
            content="Are you sure you want to delete customer"
          />
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Segment basic padded="very">
      <Container>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Roles</Table.HeaderCell>
              <Table.HeaderCell>Managed Departments</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>{userUI}</Table.Body>
        </Table>
      </Container>
    </Segment>
  );
};
